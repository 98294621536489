<template>
  <div class="component-profile">
    <div v-if="dataUser" class="w-100">
      <div v-if="!selectOrder" class="w-100">
        <h3 class="mb-5 text-center">Seguimiento de pedidos</h3>
        <p v-if="pedidos && pedidos.length == 0" class="text-center">
          Parece que aun no tienes pedidos.
        </p>
        <div
          v-for="(order, index) in pedidos"
          :key="index"
          class="container-card-pedido"
        >
          <span
            class="tag_estado_pedido"
            :style="
              `background-color: ${
                colorsState[order.id_titulo_estado]
              }; color: ${order.id_titulo_estado > 1 ? '#001F5D' : 'white'};`
            "
            >{{ order.titulo_estado }}</span
          >
          <div class="info-general-pedido">
            <p class="mb-0">
              Pedido <b>No. {{ order.pedido_id }}</b> <br />
              Fecha de pedido:
              <b
                >{{ order.pedido_fecha.split(' ')[0].split('-')[2] }} de
                {{
                  getMounthByNumber(
                    order.pedido_fecha.split(' ')[0].split('-')[1],
                  )
                }}
                del {{ order.pedido_fecha.split(' ')[0].split('-')[0] }}</b
              >
              <br />
              Valor pedido:
              <b
                >${{
                  new Intl.NumberFormat('de-DE').format(order.pedido_total)
                }}</b
              >
              <br />
              Último estado:
              <b>{{ order.status_title }} - {{ order.fecha_ultimo_estado }}</b>
            </p>
          </div>
          <button
            class="bnt-primary my-auto px-5 me-0 me-md-3"
            @click="getDataOrder(order.pedido_id)"
          >
            Ver detalles
          </button>
        </div>
      </div>
      <follow-up-order
        v-else-if="selectOrder"
        :data="dataOrder"
        @cleanOrder="selectOrder = $event"
      />
    </div>
    <div v-else class="text-center">
      <h3>Aún no has iniciado sesión</h3>
      <p>Para poder acceder a este modulo primero debes iniciar sesión.</p>
      <button class="bnt-primary" @click="$bvModal.show('modal-login')">
        Iniciar sesión
      </button>
    </div>
  </div>
</template>
<script>
import FollowUpOrder from '@/components/followOrder/FollowUpOrder'
import { mapActions, mapState } from 'vuex'
/* eslint-disable */
export default {
  components: { FollowUpOrder },
  data() {
    return {
      nohayordenes: false,
      colorsState: ['#005BC4', '#005BC4', '#005BC4', '#C6ECBB', '#FFC8C8'],
      selectOrder: false,
    }
  },
  computed: {
    ...mapState('user', ['dataUser', 'pedidos', 'orderSelect', 'dataOrder']),
  },
  methods: {
    ...mapActions('user', ['getInfoOrder']),
    getMounthByNumber(mount) {
      switch (mount) {
        case '01':
          return 'Enero'
          break
        case '02':
          return 'Febrero'
          break
        case '03':
          return 'Marzo'
          break
        case '04':
          return 'Abril'
          break
        case '05':
          return 'Mayo'
          break
        case '06':
          return 'Junio'
          break
        case '07':
          return 'Julio'
          break
        case '08':
          return 'Agosto'
          break
        case '09':
          return 'Septiembre'
          break
        case '10':
          return 'Octubre'
          break
        case '11':
          return 'Nombiembre'
          break
        case '12':
          return 'Diciembre'
          break
        default:
          return mount
          break
      }
    },
    async getDataOrder(pedidoId) {
      if (pedidoId && this.dataUser.correo) {
        await this.getInfoOrder({
          email: this.dataUser.correo,
          pedidoId: pedidoId,
        }).then(() => {
          this.selectOrder = true
        })
      }
    },
  },
}
</script>
