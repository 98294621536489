<template>
  <div class="card-product-movile">
    <img :src="product.producto_imagen" alt="" />
    <div class="data-product-movile">
      <p class="title-product-movile">
        {{ product.titulo.toLowerCase().replace(/^\w/, c => c.toUpperCase()) }}
      </p>
      <span class="sku-color-product-movile">
        SKU: {{ product.producto_sku }} <br />
        Talla: {{ product.talla }} - Color:
        {{ product.color.toLowerCase().replace(/^\w/, c => c.toUpperCase()) }}
      </span>
      <span
        v-if="product.facturado == 0"
        style="
          padding: 0.1rem 0.3rem;
          border: 1px solid tomato;
          color: tomato;
          border-radius: 3px;
          margin-bottom: 0.3rem;
        "
        >Cancelado</span
      >
      <p v-else class="price-product-movile">
        ${{ new Intl.NumberFormat('de-DE').format(product.precio) }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    product: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
}
</script>
