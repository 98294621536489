<template>
  <div class="detail-orden-select">
    <span class="btn-volver" @click="goBack()">
      <svg
        id="Shopicons_Light_ArrowBack"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 48 48"
      >
        <path
          id="Trazado_4823"
          data-name="Trazado 4823"
          d="M0,0H48V48H0Z"
          fill="none"
        />
        <g id="Shopicon">
          <g id="Grupo_5514" data-name="Grupo 5514">
            <path
              id="Trazado_4824"
              data-name="Trazado 4824"
              d="M31.293,7.292,14.586,24,31.293,40.706l1.414-1.414L17.414,24,32.707,8.706Z"
            />
          </g>
        </g>
      </svg>
      Volver
    </span>
    <div class="w-100 mt-4">
      <div class="container-card-pedido mb-5">
        <span
          class="tag_estado_pedido"
          :style="
            `background-color: ${colorsState[data.id_titulo_estado]}; color:
            ${data.id_titulo_estado > 1 ? '#001F5D' : 'white'};`
          "
          >{{ data.titulo_estado }}</span
        >
        <div class="info-general-pedido">
          <p class="mb-0">
            Pedido <b>No. {{ data.pedido_id }}</b> <br />
            Fecha de pedido:
            <b
              >{{ data.pedido_fecha.split(' ')[0].split('-')[2] }} de
              {{
                getMounthByNumber(data.pedido_fecha.split(' ')[0].split('-')[1])
              }}
              del {{ data.pedido_fecha.split(' ')[0].split('-')[0] }}</b
            >
            <br />
            Valor pedido:
            <b
              >${{
                new Intl.NumberFormat('de-DE').format(data.pedido_total)
              }}</b
            >
            <br />
            Último estado:
            <b>
              {{ data[`step_title_${data.step}`] }}
              - {{ data.fecha_ultimo_estado }}
            </b>
          </p>
        </div>
        <button
          v-if="data.pedido_entregado > 0 && !data.devolucion"
          class="bnt-outline my-auto px-5 me-0 me-md-3"
          @click="
            $router.push({
              name: 'Devoluciones',
              params: { idPedido: data.pedido_id },
            })
          "
        >
          Hacer una devolución
        </button>
        <div v-else class="pe-3">
          <p class="text-center mb-0">
            <b>¿Necesitas ayuda?</b><br />
            <a
              style="color: #8b9ca2"
              href="https://api.whatsapp.com/send?phone=573003870181&text=asesor"
              >Whatsapp 300 387 0181</a
            >
          </p>
        </div>
      </div>
      <div class="container-summary-order">
        <div class="col-md-9 col-lg-4 mx-auto p-2">
          <p>
            <b>Datos de envío</b> <br />
            {{ data.pedido_cliente_nombre }}
            {{ data.pedido_cliente_apellidos }} <br />
            {{ data.pedido_direccion_entrega }}<br />
            {{ data.observaciones }}<br v-if="data.observaciones" />
            {{ data.pedido_ciudad }}<br />
            {{ data.pedido_cliente_celular }}
          </p>
        </div>
        <div class="col-md-9 col-lg-4 mx-auto p-2">
          <p>
            <b>Detalles de pago</b> <br />
            Método de pago: {{ data.metodo_pago }} <br />
            Referencia de pago {{ data.pedido_reference_sale }} <br />
            Fecha de transacción:
            {{ data.pedido_fecha.split(' ')[0].split('-')[2] }} de
            {{
              getMounthByNumber(data.pedido_fecha.split(' ')[0].split('-')[1])
            }}
            {{ data.pedido_fecha.split(' ')[0].split('-')[0] }} <br />
            Total del pedido: ${{
              new Intl.NumberFormat('de-DE').format(data.pedido_total)
            }}
            <br />
            Articulos: {{ data.productos.length }}
          </p>
        </div>
        <div class="col-md-9 col-lg-4 mx-auto p-2">
          <div class="seguimiento-pedido">
            <p class="mb-1"><b>Seguimiento de pedido</b></p>
            <p v-if="data.seguimiento_pedido?.transportadora" class="mb-0">
              Transportadora: {{ data.seguimiento_pedido.transportadora }}
              <a
                v-if="data.seguimiento_pedido.numero_guia"
                target="_blank"
                :href="data.seguimiento_pedido.link_guia"
              >
                No. {{ data.seguimiento_pedido.numero_guia }}
              </a>
            </p>
            <p v-if="data.seguimiento_pedido?.estado_pedido" class="mb-0">
              Estado pedido: {{ data.seguimiento_pedido.estado_pedido }}
            </p>
            <p
              v-if="
                data.seguimiento_pedido?.fecha_estimada_entrega &&
                  !data.seguimiento_pedido?.fecha_entrega
              "
              class="mb-0"
            >
              Fecha estimada de entrega:
              {{ data.seguimiento_pedido.fecha_estimada_entrega }}
            </p>
            <p v-if="data.seguimiento_pedido?.fecha_entrega" class="mb-0">
              Fecha de entrega: {{ data.seguimiento_pedido.fecha_entrega }}
            </p>
          </div>
        </div>
      </div>
      <card-product-movile
        v-for="(product, index) in data.productos"
        :key="index"
        :product="product"
      />
      <div class="display-table">
        <table>
          <thead>
            <tr>
              <th class="py-3">Producto</th>
              <th class="py-3">Color</th>
              <th class="py-3">Talla</th>
              <th class="py-3">Cantidad</th>
              <th class="py-3">Valor producto</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, index) in data.productos" :key="index">
              <td>
                <div class="table-product-detail">
                  <router-link
                    :to="{
                      name: 'ProductDetail',
                      params: { url: product.url.replace('product-', '') },
                      query: { color: product.color_id },
                    }"
                  >
                    <img :src="product.producto_imagen" alt="" />
                  </router-link>
                  <div class="d-flex flex-wrap align-items-center">
                    <span
                      v-if="product.devolucion"
                      style="
                        padding: 0.1rem 0.3rem;
                        background-color: #c8d7e5;
                        border-radius: 3px;
                        font-family: 'PT Sans Bold', sans-serif;
                        margin-bottom: 0.3rem;
                        margin-right: 1rem;
                      "
                      >Devolución</span
                    >
                    <p class="mb-0 w-100 text-start">
                      {{
                        product.titulo
                          .toLowerCase()
                          .replace(/^\w/, c => c.toUpperCase())
                      }}
                    </p>
                    <p class="w-100 mb-0 my-2">
                      <span
                        v-if="product.devolucion"
                        style="
                          padding: 0.1rem 0.3rem;
                          background-color: #c8d7e5;
                          border-radius: 3px;
                          font-weight: bold;
                          margin-bottom: 0.3rem;
                        "
                        >Devolución</span
                      >
                    </p>
                  </div>
                </div>
              </td>
              <td>
                {{
                  product.color
                    .toLowerCase()
                    .replace(/^\w/, c => c.toUpperCase())
                }}
              </td>
              <td>
                {{
                  product.talla
                    .toLowerCase()
                    .replace(/^\w/, c => c.toUpperCase())
                }}
              </td>
              <td>1</td>
              <td>
                <span
                  v-if="product.facturado == 0"
                  style="
                    padding: 0.1rem 0.3rem;
                    border: 1px solid tomato;
                    color: tomato;
                    border-radius: 3px;
                    margin-bottom: 0.3rem;
                  "
                  >Cancelado</span
                >
                <b v-else>
                  ${{ new Intl.NumberFormat('de-DE').format(product.precio) }}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="price-pedido-follow-order">
        <div
          class="mr-auto mt-5 px-0 px-md-auto mt-md-0 col-12 col-md-auto order-2 order-md-1"
        >
          <div v-if="data.pedido_entregado > 0 && !data.devolucion">
            <p class="">
              <strong>Puedes realizar una devolución por pedido</strong>
            </p>
            <router-link
              style="text-decoration: none; color: #0076ff; font-size: 1rem"
              :to="{ name: 'Returns', params: { idPedido: 1234 } }"
            >
              <button
                class="bnt-outline"
                @click="
                  $router.push({
                    name: 'Devoluciones',
                    params: { idPedido: data.pedido_id },
                  })
                "
              >
                Hacer una devolución
              </button>
            </router-link>
          </div>
        </div>
        <table class="ms-auto mt-2 mt-md-0 order-1 order-md-2">
          <tbody>
            <tr>
              <td>
                <strong>Subtotal: </strong>
              </td>
              <td class="ps-4 text-end">
                ${{
                  new Intl.NumberFormat('de-DE').format(data.pedido_subtotal)
                }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Descuentos: </strong>
              </td>
              <td class="ps-4 text-end">
                {{
                  parseInt(data.pedido_descuento) > 0
                    ? '-$' +
                      new Intl.NumberFormat('de-DE').format(
                        data.pedido_descuento,
                      )
                    : '-'
                }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Envío: </strong>
              </td>
              <td class="ps-4 text-end">
                {{
                  parseInt(data.pedido_envio) > 0
                    ? '$' +
                      new Intl.NumberFormat('de-DE').format(data.pedido_envio)
                    : 'Envío gratis'
                }}
              </td>
            </tr>
            <tr>
              <td class="px-0">
                <hr />
              </td>
              <td class="px-0">
                <hr />
              </td>
            </tr>
            <tr>
              <td>
                <strong>TOTAL: </strong>
              </td>
              <td class="ps-4 text-end">
                ${{ new Intl.NumberFormat('de-DE').format(data.pedido_total) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import CardProductMovile from '@/components/Templates/CardProductMovile'
export default {
  components: { CardProductMovile },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      colorsState: ['#005BC4', '#005BC4', '#005BC4', '#C6ECBB', '#FFC8C8'],
    }
  },
  mounted() {
    if (this.$route.name == 'ProfileOrders') {
      window.scrollTo(0, 182)
    } else {
      window.scrollTo(0, 80)
    }
  },
  methods: {
    getMounthByNumber(mount) {
      let dataReturn = ''
      switch (mount) {
        case '01':
          dataReturn = 'Enero'
          break
        case '02':
          dataReturn = 'Febrero'
          break
        case '03':
          dataReturn = 'Marzo'
          break
        case '04':
          dataReturn = 'Abril'
          break
        case '05':
          dataReturn = 'Mayo'
          break
        case '06':
          dataReturn = 'Junio'
          break
        case '07':
          dataReturn = 'Julio'
          break
        case '08':
          dataReturn = 'Agosto'
          break
        case '09':
          dataReturn = 'Septiembre'
          break
        case '10':
          dataReturn = 'Octubre'
          break
        case '11':
          dataReturn = 'Nombiembre'
          break
        case '12':
          dataReturn = 'Diciembre'
          break
        default: {
          dataReturn = mount
        }
      }
      return dataReturn
    },
    goBack() {
      if (this.$route.name == 'ProfileOrders') {
        this.$emit('cleanOrder', null)
      } else {
        this.$emit('back', 1)
        // this.$router.go(-1);
      }
    },
  },
}
</script>
