var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-orden-select"},[_c('span',{staticClass:"btn-volver",on:{"click":function($event){return _vm.goBack()}}},[_c('svg',{attrs:{"id":"Shopicons_Light_ArrowBack","xmlns":"http://www.w3.org/2000/svg","width":"25","height":"25","viewBox":"0 0 48 48"}},[_c('path',{attrs:{"id":"Trazado_4823","data-name":"Trazado 4823","d":"M0,0H48V48H0Z","fill":"none"}}),_c('g',{attrs:{"id":"Shopicon"}},[_c('g',{attrs:{"id":"Grupo_5514","data-name":"Grupo 5514"}},[_c('path',{attrs:{"id":"Trazado_4824","data-name":"Trazado 4824","d":"M31.293,7.292,14.586,24,31.293,40.706l1.414-1.414L17.414,24,32.707,8.706Z"}})])])]),_vm._v(" Volver ")]),_c('div',{staticClass:"w-100 mt-4"},[_c('div',{staticClass:"container-card-pedido mb-5"},[_c('span',{staticClass:"tag_estado_pedido",style:(`background-color: ${_vm.colorsState[_vm.data.id_titulo_estado]}; color:
          ${_vm.data.id_titulo_estado > 1 ? '#001F5D' : 'white'};`)},[_vm._v(_vm._s(_vm.data.titulo_estado))]),_c('div',{staticClass:"info-general-pedido"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Pedido "),_c('b',[_vm._v("No. "+_vm._s(_vm.data.pedido_id))]),_vm._v(" "),_c('br'),_vm._v(" Fecha de pedido: "),_c('b',[_vm._v(_vm._s(_vm.data.pedido_fecha.split(' ')[0].split('-')[2])+" de "+_vm._s(_vm.getMounthByNumber(_vm.data.pedido_fecha.split(' ')[0].split('-')[1]))+" del "+_vm._s(_vm.data.pedido_fecha.split(' ')[0].split('-')[0]))]),_c('br'),_vm._v(" Valor pedido: "),_c('b',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.data.pedido_total)))]),_c('br'),_vm._v(" Último estado: "),_c('b',[_vm._v(" "+_vm._s(_vm.data[`step_title_${_vm.data.step}`])+" - "+_vm._s(_vm.data.fecha_ultimo_estado)+" ")])])]),(_vm.data.pedido_entregado > 0 && !_vm.data.devolucion)?_c('button',{staticClass:"bnt-outline my-auto px-5 me-0 me-md-3",on:{"click":function($event){return _vm.$router.push({
            name: 'Devoluciones',
            params: { idPedido: _vm.data.pedido_id },
          })}}},[_vm._v(" Hacer una devolución ")]):_c('div',{staticClass:"pe-3"},[_vm._m(0)])]),_c('div',{staticClass:"container-summary-order"},[_c('div',{staticClass:"col-md-9 col-lg-4 mx-auto p-2"},[_c('p',[_c('b',[_vm._v("Datos de envío")]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(_vm.data.pedido_cliente_nombre)+" "+_vm._s(_vm.data.pedido_cliente_apellidos)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.data.pedido_direccion_entrega)),_c('br'),_vm._v(" "+_vm._s(_vm.data.observaciones)),(_vm.data.observaciones)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(_vm.data.pedido_ciudad)),_c('br'),_vm._v(" "+_vm._s(_vm.data.pedido_cliente_celular)+" ")])]),_c('div',{staticClass:"col-md-9 col-lg-4 mx-auto p-2"},[_c('p',[_c('b',[_vm._v("Detalles de pago")]),_vm._v(" "),_c('br'),_vm._v(" Método de pago: "+_vm._s(_vm.data.metodo_pago)+" "),_c('br'),_vm._v(" Referencia de pago "+_vm._s(_vm.data.pedido_reference_sale)+" "),_c('br'),_vm._v(" Fecha de transacción: "+_vm._s(_vm.data.pedido_fecha.split(' ')[0].split('-')[2])+" de "+_vm._s(_vm.getMounthByNumber(_vm.data.pedido_fecha.split(' ')[0].split('-')[1]))+" "+_vm._s(_vm.data.pedido_fecha.split(' ')[0].split('-')[0])+" "),_c('br'),_vm._v(" Total del pedido: $"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.data.pedido_total))+" "),_c('br'),_vm._v(" Articulos: "+_vm._s(_vm.data.productos.length)+" ")])]),_c('div',{staticClass:"col-md-9 col-lg-4 mx-auto p-2"},[_c('div',{staticClass:"seguimiento-pedido"},[_vm._m(1),(_vm.data.seguimiento_pedido?.transportadora)?_c('p',{staticClass:"mb-0"},[_vm._v(" Transportadora: "+_vm._s(_vm.data.seguimiento_pedido.transportadora)+" "),(_vm.data.seguimiento_pedido.numero_guia)?_c('a',{attrs:{"target":"_blank","href":_vm.data.seguimiento_pedido.link_guia}},[_vm._v(" No. "+_vm._s(_vm.data.seguimiento_pedido.numero_guia)+" ")]):_vm._e()]):_vm._e(),(_vm.data.seguimiento_pedido?.estado_pedido)?_c('p',{staticClass:"mb-0"},[_vm._v(" Estado pedido: "+_vm._s(_vm.data.seguimiento_pedido.estado_pedido)+" ")]):_vm._e(),(
              _vm.data.seguimiento_pedido?.fecha_estimada_entrega &&
                !_vm.data.seguimiento_pedido?.fecha_entrega
            )?_c('p',{staticClass:"mb-0"},[_vm._v(" Fecha estimada de entrega: "+_vm._s(_vm.data.seguimiento_pedido.fecha_estimada_entrega)+" ")]):_vm._e(),(_vm.data.seguimiento_pedido?.fecha_entrega)?_c('p',{staticClass:"mb-0"},[_vm._v(" Fecha de entrega: "+_vm._s(_vm.data.seguimiento_pedido.fecha_entrega)+" ")]):_vm._e()])])]),_vm._l((_vm.data.productos),function(product,index){return _c('card-product-movile',{key:index,attrs:{"product":product}})}),_c('div',{staticClass:"display-table"},[_c('table',[_vm._m(2),_c('tbody',_vm._l((_vm.data.productos),function(product,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticClass:"table-product-detail"},[_c('router-link',{attrs:{"to":{
                    name: 'ProductDetail',
                    params: { url: product.url.replace('product-', '') },
                    query: { color: product.color_id },
                  }}},[_c('img',{attrs:{"src":product.producto_imagen,"alt":""}})]),_c('div',{staticClass:"d-flex flex-wrap align-items-center"},[(product.devolucion)?_c('span',{staticStyle:{"padding":"0.1rem 0.3rem","background-color":"#c8d7e5","border-radius":"3px","font-family":"'PT Sans Bold', sans-serif","margin-bottom":"0.3rem","margin-right":"1rem"}},[_vm._v("Devolución")]):_vm._e(),_c('p',{staticClass:"mb-0 w-100 text-start"},[_vm._v(" "+_vm._s(product.titulo .toLowerCase() .replace(/^\w/, c => c.toUpperCase()))+" ")]),_c('p',{staticClass:"w-100 mb-0 my-2"},[(product.devolucion)?_c('span',{staticStyle:{"padding":"0.1rem 0.3rem","background-color":"#c8d7e5","border-radius":"3px","font-weight":"bold","margin-bottom":"0.3rem"}},[_vm._v("Devolución")]):_vm._e()])])],1)]),_c('td',[_vm._v(" "+_vm._s(product.color .toLowerCase() .replace(/^\w/, c => c.toUpperCase()))+" ")]),_c('td',[_vm._v(" "+_vm._s(product.talla .toLowerCase() .replace(/^\w/, c => c.toUpperCase()))+" ")]),_c('td',[_vm._v("1")]),_c('td',[(product.facturado == 0)?_c('span',{staticStyle:{"padding":"0.1rem 0.3rem","border":"1px solid tomato","color":"tomato","border-radius":"3px","margin-bottom":"0.3rem"}},[_vm._v("Cancelado")]):_c('b',[_vm._v(" $"+_vm._s(new Intl.NumberFormat('de-DE').format(product.precio))+" ")])])])}),0)])]),_c('div',{staticClass:"price-pedido-follow-order"},[_c('div',{staticClass:"mr-auto mt-5 px-0 px-md-auto mt-md-0 col-12 col-md-auto order-2 order-md-1"},[(_vm.data.pedido_entregado > 0 && !_vm.data.devolucion)?_c('div',[_vm._m(3),_c('router-link',{staticStyle:{"text-decoration":"none","color":"#0076ff","font-size":"1rem"},attrs:{"to":{ name: 'Returns', params: { idPedido: 1234 } }}},[_c('button',{staticClass:"bnt-outline",on:{"click":function($event){return _vm.$router.push({
                  name: 'Devoluciones',
                  params: { idPedido: _vm.data.pedido_id },
                })}}},[_vm._v(" Hacer una devolución ")])])],1):_vm._e()]),_c('table',{staticClass:"ms-auto mt-2 mt-md-0 order-1 order-md-2"},[_c('tbody',[_c('tr',[_vm._m(4),_c('td',{staticClass:"ps-4 text-end"},[_vm._v(" $"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.data.pedido_subtotal))+" ")])]),_c('tr',[_vm._m(5),_c('td',{staticClass:"ps-4 text-end"},[_vm._v(" "+_vm._s(parseInt(_vm.data.pedido_descuento) > 0 ? '-$' + new Intl.NumberFormat('de-DE').format( _vm.data.pedido_descuento, ) : '-')+" ")])]),_c('tr',[_vm._m(6),_c('td',{staticClass:"ps-4 text-end"},[_vm._v(" "+_vm._s(parseInt(_vm.data.pedido_envio) > 0 ? '$' + new Intl.NumberFormat('de-DE').format(_vm.data.pedido_envio) : 'Envío gratis')+" ")])]),_vm._m(7),_c('tr',[_vm._m(8),_c('td',{staticClass:"ps-4 text-end"},[_vm._v(" $"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.data.pedido_total))+" ")])])])])])],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center mb-0"},[_c('b',[_vm._v("¿Necesitas ayuda?")]),_c('br'),_c('a',{staticStyle:{"color":"#8b9ca2"},attrs:{"href":"https://api.whatsapp.com/send?phone=573003870181&text=asesor"}},[_vm._v("Whatsapp 300 387 0181")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-1"},[_c('b',[_vm._v("Seguimiento de pedido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"py-3"},[_vm._v("Producto")]),_c('th',{staticClass:"py-3"},[_vm._v("Color")]),_c('th',{staticClass:"py-3"},[_vm._v("Talla")]),_c('th',{staticClass:"py-3"},[_vm._v("Cantidad")]),_c('th',{staticClass:"py-3"},[_vm._v("Valor producto")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{},[_c('strong',[_vm._v("Puedes realizar una devolución por pedido")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Subtotal: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Descuentos: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("Envío: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"px-0"},[_c('hr')]),_c('td',{staticClass:"px-0"},[_c('hr')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',[_vm._v("TOTAL: ")])])
}]

export { render, staticRenderFns }